import Swiper from "swiper";
import { Navigation, Scrollbar } from "swiper/modules";
import { SwiperOptions } from "swiper/types/swiper-options";
import "./List_Article_Swiper.scss";

(() => {
  const SwiperInit = () => {
    const articles = document.querySelectorAll<HTMLElement>(
      ".article-swiper-container"
    );
    for (const article of articles) {
      const swiperEl = article.querySelector<HTMLElement>(".swiper");
      const scrollbarEl =
        swiperEl.querySelector<HTMLElement>(".swiper-scrollbar");
      const prevEl = article.querySelector<HTMLElement>(".swiper-button-prev");
      const nextEl = article.querySelector<HTMLElement>(".swiper-button-next");
      const swiperOptions: SwiperOptions = {
        modules: [Scrollbar, Navigation],
        slidesPerView: 1,
        spaceBetween: 25,
        breakpoints: {
          500: {
            slidesPerView: 2,
            spaceBetween: 25,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 45,
          },
        },
        scrollbar: {
          el: scrollbarEl,
          dragSize: 100,
        },
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
      };
      const swiper = new Swiper(swiperEl, swiperOptions);
    }
  };

  const ImgTagTextInit = () => {
    const containers = document.querySelectorAll<HTMLElement>(
      ".article-swiper-container"
    );
    for (const container of containers) {
      const slides = container.querySelectorAll<HTMLElement>(".swiper-slide");
      for (const slide of slides) {
        const articleCategoryContainer = slide.querySelector<HTMLElement>(
          ".article-category-container"
        );
        if (!articleCategoryContainer) continue;

        const newsImgTag = slide.querySelector<HTMLElement>(".news-img-tag");

        const categories = [
          ...articleCategoryContainer.querySelectorAll<HTMLElement>("span"),
        ].map((s) => s.innerHTML);

        if (categories.includes("News")) {
          newsImgTag.innerHTML = "News";
          newsImgTag.classList.add("bg-primary");
        }
        if (categories.includes("Events")) {
          newsImgTag.innerHTML = "Event";
          newsImgTag.classList.add("bg-orange");
        }
        articleCategoryContainer.remove();
      }
    }
  };
  const win = window as any;
  win.ListArticleSwiper ??= {};
  win.ListArticleSwiper.Initialised ??= false;
  if (!win.ListArticleSwiper.Initialised) {
    SwiperInit();
    ImgTagTextInit();
    win.ListArticleSwiper.Initialised = true;
  }
})();
